import React, {memo} from 'react'
import { useSelector } from 'react-redux';
import {useState, useEffect, useLayoutEffect} from 'react'
import {useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import d3Tip from 'd3-tip'

import Loader from '../Loader'

import {WebSockets} from '../WebSocket/webSockets'

import store from '../../app/Store'

var d3 = require('d3');

var tool_tip = d3Tip()
.attr('class', 'd3-tip')
.html('<div id = "tipDiv"></div>')

var panzoom = require('panzoom');

var wsPrices = []

//ça va chercher la treemap dans le model dataC.json à personaliser sur BDD. ensuite pour mignature ça va chercher sur localcoin
//je prends que les 1000 premier de local cooin

const CryptoTreeMap = () => {
  const navigate = useNavigate ();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [data, setData] = useState(!isMobile ? require('./data.json') : require('./dataMobile.json'))
  const [zoomData, setZoomData] = useState(data);

  console.log(isMobile)


  let format = d3.format(",d");

  const wsx = WebSockets()

  const webSocketPrices = () => {
    const storeData = store.getState()
    const history = storeData?.reducers?.webSockets?.history
    wsPrices = history
  }

  function handleResize() {
    let chartDiv = document.getElementById("treeMapChart")
    setWidth(chartDiv.clientWidth);
    setHeight(chartDiv.clientHeight);
    return redraw
  }

  useLayoutEffect(() => {
    var instance = panzoom(document.getElementById("chartSVG"), {
      zoomSpeed: 0.06,
      maxZoom: 20,
      minZoom: 1,
      bounds: true,
      boundsPadding: 1,
      touchAction:""
    });
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);


  useEffect(() => {
    var run = 0
    const interval = setInterval(() => {
      run += 1
      if (refresh < 30){ //mettre plutot idle
        setRefresh(refresh => refresh  + 1);
        webSocketPrices()
      }
      else if (refresh >= 30){
        clearInterval(interval);
      }
      }, 10000);
      return () => clearInterval(interval);
  }, [refresh]);

  useEffect(() => {
    window.addEventListener("resize", () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
        !ismobile ? setData(require('./data.json')) : setData(require('./dataMobile.json'))
    }, false);
  }, [isMobile]);

  const {localCoins, isLoading} = useSelector((state) => state.reducers.localCoins); //prend trop de temps. à splitter éventuellement.
  //const price = useSelector((state) => state?.reducers?.webSockets?.price);

  
 
  function redraw() {

    d3.select("#chartSVG").selectAll("*").remove()
    
    let colors = [
      "#AA2121",
      "#C84040",
      "#ED7171",
      "#7EC17E",
      "#518651",
      "grey"
    ];
    
    function getColor(val) {
      let color = "red"
      const x = parseFloat(val)
      switch (true) {
        case (x > 5):
          color = colors[4]
          break;
        case (x > 0):
          color = colors[3]
          break;
        case (x > -5):
          color = colors[2]
          break;
        case (x > -10):
          color = colors[1]
          break;
        case (x > -100):
          color = colors[0]
          break;
        default:
          color = colors[5]
      }
      return color
    }

    d3.select("#chartSVG").html("");
    

    const svg = d3.select("#chartSVG");
    
    svg.call(tool_tip);

    let chart = () => {

      var root = treemap(filteredData);
      var touchmoved;
      
      const granParent = svg
      .selectAll()
        .data(
          root.descendants().filter(function (d) {
            return d.depth === 1;
          })
        )
        .enter()
        .append("g")
        .attr("transform", d => `translate(${d.x0},${d.y0})`)
        .attr("id", "granParent")

        granParent
        .append("rect")
        .on("click", function(d) { return setZoomData((data.children.filter(function (item) { return item.name === d.target.__data__.data.name }))[0]); })
        .attr("id", d => (d.leafUid = "#granParent").id)
        .attr("class", (d) => "node level-" + d.depth)
        .attr("fill-opacity", 0)
        .attr("width", d => d.x1 - d.x0)
        .attr("height", d => d.y1 - d.y0)

      const leaf = d3.select("#chartSVG")
        .selectAll()
        .data(
          root.leaves()
        )
        .enter()
        .append("g")
        .attr("transform", d => `translate(${d.x0},${d.y0})`)
        .on('mouseover', function (event, d) {
          const data = d
          const [x, y] = d3.pointer(event, svg)
          const coinUuid = data.data.uuid
          const coinName = localCoins ? localCoins.filter(coin => coin.uuid === coinUuid)[0]?.name : data.data.name
          const coinChange = localCoins ? localCoins.filter(coin => coin.uuid === coinUuid)[0]?.change : "-"
          const coinVolume = localCoins ? localCoins.filter(coin => coin.uuid === coinUuid)[0]?.volume : "-"
          const coinMarketCap = localCoins ? localCoins.filter(coin => coin.uuid === coinUuid)[0]?.marketCap : "-"
          const coinSparkLine = localCoins.filter(coin => coin.uuid === coinUuid)[0]?.sparkline ? localCoins.filter(coin => coin.uuid == coinUuid)[0]?.sparkline : ""
          const coinIconUrl = localCoins ? localCoins.filter(coin => coin.uuid === coinUuid)[0]?.iconUrl : ""

          tool_tip
          .show(this)
          .offset(function() {if (x > 1000) {
            return [-350, -500] } 
            else { return [350, -100] }
          })
          .offset(function() {if (y > 500) {
            return [-350, (d.y1 - d.y0) / 5] } 
            else { return [0, (d.y1 - d.y0) / 5] }
          })
          // il n ya que le 2eme offset qui marche. chercher sur internet comment faire x et y pour le tooltip

          //.direction('e') //mixer tout ça pour qu'il ne sorte jamais du cadre
          /*
          .offset(function() {if (x > 1000 && y > 500) {
            return [x,y] } 
            else if (x < 1000 && y > 500) { return [x,y] }
            else if (x > 1000 && y < 500) { return [x,y] }
            else if (x < 1000 && y < 500) { return [x,y] }
            else {return [0,0]}
          })
          */
          mouseOver(coinName, coinChange, coinVolume, coinMarketCap, coinSparkLine, coinIconUrl)
        })
        .on('mouseout', tool_tip.hide);

        //  leaf.append("title").text(
        //     d =>
        //       `${d
        //         .ancestors()
        //         .reverse()
        //         .map(d => d.data.name)
        //         .join("/")}\n${format(d.value)}`       
        //  );

      leaf
        .append("rect")
        .attr("id", d => (d.leafUid = "#leaf").id)
        .attr("class", (d) => "node level-" + d.depth)
        .attr("fill", (d) => getColor(
          localCoins ? localCoins.filter(coin => coin.uuid === d.data.uuid)[0]?.change
          : 0
        ))
        .attr("fill-opacity", 1.0)
        .attr("width", d => d.x1 - d.x0)
        .attr("height", d => d.y1 - d.y0)
        .on('mouseenter', function(event, d) {
          const parentx = d?.parent?.data?.name
          d3.selectAll("rect").filter(function (d) {
            return d?.data?.name === parentx

          })
          .attr("fill", "rgb(0, 0, 0)")
          .attr("fill-opacity", 0.8)
          d3.selectAll("rect").filter(function (d) {
            return d?.parent?.data?.name === parentx
          })
          .style("outline-width", "0.5px") //est ce que ce deuxième style ne ralenti pas un peu ?
          .style("outline-color", "#fff")
          .style("outline-style", "solid") 
        })
        .on('mouseleave', function(event, d) {
          const parentx = d?.parent?.data?.name
          d3.selectAll("rect").filter(function (d) {
            return d?.data?.name === parentx
          })
          .attr("fill", "none")
          .attr("fill-opacity", 0)
          d3.selectAll("rect").filter(function (d) {
            return d?.parent?.data?.name === parentx
          })
          .style("outline-style", "none") 
        })
      .on("click", function(event, d) {
        navigate(`/CoinPage/${d.data.uuid}`, { replace: true });
        tool_tip.hide(d)
      })
      .on('touchend', function(e, d){
        if(touchmoved !== true){
          navigate(`/CoinPage/${d.data.uuid}`, { replace: true });
        }
      }).on('touchmove', function(e){
        touchmoved = true;
      }).on('touchstart', function(){
        touchmoved = false;
      })

      // leaf.append("clipPath")
      //     .attr("id", d => (d.clipUid = ("#clip")).id)
      //     .append("use")
      //     .attr("xlink:href", d => d.leafUid.href);
  
      let txt = leaf
        .append("text")
        .attr("fill", "#fff")
        .attr("text-anchor", "middle")
        .attr("class", "shadow")
        // .attr("dy", "1.7em")
        .attr("y", function () {
          const parentData = d3.select(this.parentNode).datum();
          return (parentData.y1 - parentData.y0) / 2;
        })
        // .attr("x", "1.7em")
        // .attr("unicode-bidi","isolate-override")
        .attr("font-size", d => Math.min(d.x1 - d.x0, d.y1 - d.y0) / 6)
  
      txt.append("tspan") //régler valeur si faux
        .text(d =>
          localCoins ? localCoins.filter(coin => coin.uuid === d.data.uuid)[0]?.symbol
          : "-")
        .attr("class", "title")
        .attr("dy", "-1.5em")
        .attr("x", function () {
          const parentData = d3.select(this.parentNode).datum();
          return (parentData.x1 - parentData.x0) / 2;
        });
  
      txt.append("tspan") //régler valeur si faux
        .text(d =>
          !wsPrices[d.data.uuid]?.price? (
          localCoins ? `$${Number(localCoins.filter(coin => coin.uuid === d.data.uuid)[0]?.price / 1).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`
          : "-") : Number(wsPrices[d.data.uuid]?.price).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}))
        .attr("class", "price")
        .attr("dy", "1.4em")
        .attr("x", function () {
          const parentData = d3.select(this.parentNode).datum();
          return (parentData.x1 - parentData.x0) / 2;
        });
  
      
      txt.append("tspan") //régler valeur si faux
        .text(d =>
          {const change = `${localCoins.filter(coin => coin.uuid === d.data.uuid)[0]?.change}%`
          return localCoins ? (change > 0) ? `+${change}` : `${change}`
          : "-"
          }) 
        .attr("class", "percent")
        .attr("dy", "1.4em")
        .attr("x", function () {
          const parentData = d3.select(this.parentNode).datum();
          return (parentData.x1 - parentData.x0) / 2;
        });

        txt.append("tspan")
        .attr("dy", "1.4em")
        .attr("x", function () {
          const parentData = d3.select(this.parentNode).datum();
          return (parentData.x1 - parentData.x0) / 2;
        });


        //

        /*
        .append("text")
        .attr("fill", "#fff")
        .attr("text-anchor", "middle")
        .attr("class", "shadow")
        .attr("y", function () {
          const parentData = d3.select(this.parentNode).datum();
          return (parentData.y1 - parentData.y0) / 2;
        })
        .attr("font-size", d => Math.min(d.x1 - d.x0, d.y1 - d.y0) / 6)
        .append("tspan") 
        .text('anas')
        .attr("class", "title")
        .attr("dy", "1.4em")
        .attr("x", function () {
          const parentData = d3.select(this.parentNode).datum();
          return (parentData.x1 - parentData.x0) / 2;
        });
        */

  
      // Add a <tspan class="text"> for every text line.
      // txt.selectAll("tspan.text")
      //     .data(d => d.text.split("\n"))
      //     .enter()
      //     .append("tspan")
      //     .attr("class", "text")
      //     .text(d => d)
      //     .attr("dy", "1.1em")
      //     .attr("x", function() {
      //       const parentData = d3.select(this.parentNode).datum();
      //       return (parentData.x1 - parentData.x0) / 2;
      //     });  
  
      //       .selectAll("tspan")
      //       .data(d =>
      //         d.data.name
      //           .split(/(?=[A-Z][^A-Z])/g)
      //           .concat(format(d.data.volume))
      //           .concat("+%"+format(d.data.pc))
      //       )
      //       .enter()
      //       .append("tspan")
      //       // .style("width", d => d.x1 - d.x0)
      //       // .style("height", d => d.y1 - d.y0)
      //       // .attr("direction" ,"rtl")
      //       // .attr("xml:lang","fa")
      //       // .attr("unicode-bidi","bidi-override")
      //       // .attr("transform", "(250 250 250 250)")
      //       // .style("text-anchor", "start")
      //       // .attr("dominant-baseline", "central")
      //       // .attr("y", function() {
      //       //   const parentData = d3.select(this.parentNode).datum();
      //       //   return (parentData.y1 - parentData.y0) / 2;
      //       // })
      //       .attr("dy", "1.4em")
      //       .attr("x", function() {
      //         const parentData = d3.select(this.parentNode).datum();
      //         return (parentData.x1 - parentData.x0) / 2;
      //       })
      //       .attr("font-weight", (d, i, nodes) =>
      //         i === nodes.length - 1 ? "400" : "700"
      //       )
      //       .text(d => d);
  
      // Add title for the top level

      svg
        .selectAll("titles")
        .data(
          root.descendants().filter(function (d) {
            return d.depth === 1;
          })
        )
        .enter()
        .append('g')
        .attr("x", (d) => d.x0)
        .attr("y", (d) => d.y0)
        .attr("dx", (d) => d.x0 + d.x1)
        .attr("dy", (d) => d.y0 + d.y1)
        .attr("id", "title")
        .append("text")
        .attr("x", (d) => d.x0 + 3)
        .attr("y", (d) => d.y0 + 18)
        .text((d) => d.data.name)
        // .attr("font-size", d => Math.max(d.x1 - d.x0, d.y1 - d.y0) / 22)
        .attr("font-size", "16px")
        .attr("font-weight", "400")
        .attr("fill", "#fff")

      svg
      .attr("width", width)
      .attr("height", height)
      .classed("svg-content-responsive", true);

      return svg.node();

    };

    function zoom() {

      if (zoomData !== data) {
        d3.selectAll("#title").remove()
        d3.selectAll("#granParent").remove()
        const backward = svg
        .append("g")
        .attr("transform", function(d, i) { return "translate(20," + i * 55 + ")"; })

        backward
        .append("rect")
        .attr("width", 150)
        .attr("y", 20) //à peaufiner
        .attr("height", 35 - 1)
        .on("click", function (d) {return setZoomData(data)})

        backward
        .append("text")
        .attr("dy", "2.6em")
        .attr("dx", "2.8em")
        .text("previous")
        .attr("font-size", "16px")
        .attr("font-weight", "400")
        .attr("fill", "#000")
        //.text(function(d) { return d; });
      }

    }

 

    function mouseOver(coinName, coinChange, coinVolume, coinMarketCap, coinSparkLine, coinIconUrl) {

      var tipSVG = d3.select("#tipDiv")
      .append("svg")
      .attr("width", 220)
      .attr("height", 200)
      .attr("id", "toolTipSVG")

      //mettre une direction dynamique. exemples de code dispo https://gist.github.com/davegotz/bd54b56723c154d25eedde6504d30ad7

      tipSVG.append("svg:image")
      .attr("xlink:href", coinIconUrl)
      .attr("width", 50)
      .attr("height", 50)
      .attr("x", 85)
      .attr("y",0);

      tipSVG.append("text")
      .text("Name : " + coinName)
      .attr("x",10)
      .attr("y", 70)
      .attr("id", "toolTipTxt")

      tipSVG.append("text")
      .text("Volume : " +  `$${format(coinVolume / 1)}`)
      .attr("x",10)
      .attr("y", 90)
      .attr("id", "toolTipTxt")
        
      tipSVG.append("text")
        .text("Market Cap : " +  `$${format(coinMarketCap / 1)}`)
        .attr("x",10)
        .attr("y", 110)
        .attr("id", "toolTipTxt")

      var maxLine = Math.max(...coinSparkLine) * 1.1
      var minLine = Math.min(...coinSparkLine) * 0.9
      const lines = coinSparkLine.length

      var sparkLineData = [...coinSparkLine].reverse().map(function (item, i=0) {
        return {scale : i+1, value : Number(item)}
      })

      var x_tooltip = d3
        .scaleLinear()
        .domain([0, lines - 1])
        .range([0, 180])

      var y_tooltip = d3.scaleLinear().domain([minLine, maxLine]).range([100, 0]);

      const line = d3
        .line()
        .x(d => x_tooltip(d.scale) )
        .y(d => y_tooltip(d.value) )
        .curve(d3.curveCatmullRom.alpha(.5))     

      tipSVG
      .append("path")
      .datum(sparkLineData)
      .style("stroke-width", 1.5)
      .style("fill-opacity", 0.5)
      .attr('d', line) // do your magic, line!
      .attr("transform", "translate(20, 250) scale(1, -2)") // a peaufiner
      .style("fill", (coinChange > 0) ? "green" : "red")
      .style("stroke", (coinChange > 0) ? "green" : "pink")


    }

  
    let filteredData = d3
      .hierarchy(zoomData)
      .sum(d => d.value)
      .sort((a, b) => b.height - a.height || b.value - a.value);
  
    let reg = d3.selectAll("input[name='dtype']").on("change", function () {
      let dtype = this.value;
    });
  
    let treemap = d3
      .treemap()
      .size([width, height])
      .padding(1)
      .paddingRight(3)
      .paddingTop(25)
      .round(true);


  
    // let charsts = d3.select("#treeMapChart");
    chart();
    zoom()
  }

  if (isLoading) return <Loader/>
  if (!data) return <Loader/>

  return (
    <>
    <Helmet>
        <title>Treemap - SoCoins</title>
        <meta name="description" content="Cryptocurrencies Treemap" />
        <meta name="keywords" content="crypto, cryptocurrencies, treemap" />
    </Helmet>
    <div>
      {redraw()}
    </div>
    </>

  )
}

export default memo(CryptoTreeMap)