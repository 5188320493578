import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { GoogleLogin, GoogleLogout } from '@react-oauth/google';
import { gapi } from 'gapi-script';
import { useDispatch } from 'react-redux';
import {jwtDecode} from 'jwt-decode';

import { Avatar, Button, Col, Row, Typography, Card } from 'antd';
import { LockOutlined } from "@ant-design/icons";
import CustomInput from './CustomInput'
import { signin, signup, googleUser } from '../../actions/auth';

const initialState = {firstName: '', lastName: '', email: '', password: '', confirmPassword: ''}

const Auth = () => {
  const [isSignUp, setIsSignUp] = useState(false)
  const [formData, setFormData] = useState(initialState)
  const dispatch = useDispatch();
  const navigate = useNavigate ();

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if(isSignUp) {
      dispatch(signup(formData, navigate))
    } else {
      dispatch(signin(formData, navigate))

    }
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value })
  };

  const switchMode = () => {
    setIsSignUp((prevIsSignUp) => !prevIsSignUp);

  };

  const googleSuccess = async (res) => {

    //copier les informations de profil google dans la base de données lors du sign up

    const result = jwtDecode(res?.credential)
    const token = res?.credential

    
    try {
      dispatch(googleUser(result, navigate))
      navigate(`/`) //vérifier le replace

    } catch (error) {
      console.log(error)
    }

  };

  const googleFailure = (error) => {
    console.log(error)
    console.log("Google Sign In Was Unsuccessful. Try Again Later")
  };

  return (
    <>
        <Helmet>
            <title>Authentification - SoCoins</title>
            <meta name="robots" content="noindex" />
        </Helmet>
        <div className="auth-container">
        <Row justify="center">
            <Col xl={8} xs={24}>
                <Card title="Authentification">
                  <Row gutter={[8,8]}>
                    <Col>
                      <Avatar
                        icon={<LockOutlined />}
                        alt='username'
                      />
                    </Col>
                    <Col>
                      <Typography.Title level={3}>
                        {!isSignUp ? 'Sign In' : 'Sign Up'} 
                      </Typography.Title>
                    </Col>
                  </Row>
                  <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Row gutter={[8,8]}>
                      {
                        isSignUp && (
                          <>
                              <CustomInput id="firstName" placeholder="First Name" handleChange={handleChange} half/>
                              <CustomInput id="lastName" placeholder="Last Name" handleChange={handleChange} half/>
                          </>
                        )
                      }
                        <CustomInput id="email" placeholder="Email Adress" handleChange={handleChange} type="email"/>
                        <CustomInput id="password" placeholder="Password" handleChange={handleChange}/>
                        {isSignUp && <CustomInput id="confirmPassword" placeholder="Repeat Password" handleChange={handleChange}/>}
                    </Row>
                    <Row>
                      <Col xl={12} xs={24}><Button className="auth-button-submit" type="primary" size="medium" htmlType="submit">{!isSignUp ? 'Sign In' : 'Sign Up'}</Button></Col>
                      <Col xl={12} xs={24}>
                        <Button htmlType="submit" className="auth-button-new-account" type="link" onClick={switchMode}>
                          {isSignUp ? 'Already have an account ? Sign In' : "Don't have an account ? Sign Up"}
                        </Button ></Col>
                      <Col xl={24} md={24} xs={24}>
                        <div className="google-sign-in">
                          <div>
                            <GoogleLogin
                              onSuccess={googleSuccess}
                              onFailure={googleFailure}
                              logo_alignment={"center"}
                              useOneTap
                              auto_select
                              state_cookie_domain='http://socoins.io/'
                            />
                          </div>
                        </div>
                        <div className="google-sign-in">

                        </div>
                      </Col>
                    </Row>
                  </form>
                </Card>
            </Col>
        </Row>
        </div>
    </>
  )
}

export default Auth