import React, { useState, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation  } from 'react-router-dom';
import { Routes, Route, Link, redirect } from 'react-router-dom';
import { Layout, Typography, Space, ConfigProvider, theme } from 'antd';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { setTheme } from './actions/setTheme';
import { setColors } from './actions/setTheme';

import {Home, Auth, Profil, CryptoTreeMap, CoinPage, TopCoins, Cryptolist, CryptoIndex, WhatIf, Compare} from './components';
import Navbar from './components/Navbar';
import TickerTape from './components/TickerTape';
import Portfolio from './components/Portfolio/Portfolio';
import './App.css';
import Loader from './components/Loader';

//au lieu de mettre les presets algo qui servent plusà rien, intégrer

const App = () => {
    const dispatch = useDispatch()
    const location = useLocation().pathname.substring(1,)
    const switchTheme = useSelector((state) => state.reducers.setTheme);
    const colors = useSelector((state) => state.reducers.setColors);

    var rootcss = document.querySelector(':root');

    useEffect(() => {
        switchTheme == true ? rootcss.setAttribute("data-theme", "dark") : rootcss.setAttribute("data-theme", "light")
        const colorPrimary = getComputedStyle(document.documentElement).getPropertyValue('--colorPrimary');
        const darkPrimary = getComputedStyle(document.documentElement).getPropertyValue('--darkPrimary');
        const lightPrimary = getComputedStyle(document.documentElement).getPropertyValue('--lightPrimary');
        const lightGreySecondary = getComputedStyle(document.documentElement).getPropertyValue('--greyLightSecondary');
        const darkPrimaryConst = getComputedStyle(document.documentElement).getPropertyValue('--darkPrimaryConst');
        const bgPrimary = getComputedStyle(document.documentElement).getPropertyValue('--bgPrimary');
        const bgSecondary = getComputedStyle(document.documentElement).getPropertyValue('--bgSecondary');
        dispatch(setColors({colorPrimary : colorPrimary, darkPrimary : darkPrimary, lightPrimary : lightPrimary, lightGreySecondary : lightGreySecondary, darkPrimaryConst : darkPrimaryConst, bgPrimary : bgPrimary, bgSecondary : bgSecondary}));
    }, [switchTheme]);

    useEffect(() => {
        if (switchTheme==true) {
            dispatch(setTheme(true))
        }
    },[])

    if (!colors.colorPrimary) return <Loader/> ;

    return (
        <HelmetProvider>
            <ConfigProvider
            theme={{
                token:{
                    colorPrimary: colors.colorPrimary,
                    colorTextBase: colors.darkPrimary,
                    colorBgContainer: colors.lightPrimary,
                    colorBgElevated:colors.bgPrimary,
                },
                components: {
                    Table: {
                        borderColor: colors.lightGreySecondary
                    },
                    Segmented: {
                        trackBg:colors.bgSecondary,
                        itemColor:colors.darkPrimary
                    },
                    Button: {
                        primaryShadow:colors.bgPrimary
                    },
                    Menu: {
                        itemSelectedBg:colors.bgSecondary
                    },
                    Select: {
                        optionActiveBg:colors.lightPrimary,
                    },

                },
            }}
            >
                <GoogleOAuthProvider clientId="257069776349-irl84f2uin14an6cu4ggjqibq3n2ciem.apps.googleusercontent.com">
                    <div>
                        <Navbar />
                    </div>
                    {location=='treemap'? 
                        <div></div> : 
                        <div className="ticker-tape">
                            <TickerTape />
                        </div>
                    }
                    <div className="main">
                        <Layout>
                            <div className="routes">
                                <Routes>
                                    <Route exact path="/" element={<Home />} /> 
                                    <Route exact path="/cryptolist" element={<Cryptolist />} />
                                    <Route exact path="/treemap" element={<CryptoTreeMap />} />
                                    <Route exact path="/coinpage/:coinId" element={<CoinPage />} />
                                    <Route exact path="/topcoins" element={<TopCoins />} />
                                    <Route exact path="/cryptoindex" element={<CryptoIndex />} />
                                    <Route exact path="/auth" element={<Auth />} />
                                    <Route exact path="/profil" element={<Profil />} />
                                    <Route exact path="/portfolio" element={<Portfolio />} />
                                    <Route exact path="/whatif" element={<WhatIf />} />
                                    <Route exact path="/compare" element={<Compare />} />
                                </Routes>
                            </div>
                        </Layout>
                        {location=='treemap'? 
                        <div></div> : 
                            <div className="footer">
                                <Space>
                                    <Typography.Text className="footer-title" strong='true'><Link className="footer-links" to="/">SoCoins.io</Link></Typography.Text>
                                    <Typography.Text className="footer-title"> - </Typography.Text>
                                    <Typography.Text className="footer-title"><Link className="footer-links" to="/cryptolist">Cryptocurrencies</Link></Typography.Text>
                                    <Typography.Text className="footer-title"><Link className="footer-links" to="/treemap">Treemap</Link></Typography.Text>
                                    <Typography.Text className="footer-title"><Link className="footer-links" to="/topcoins">TopCoins</Link></Typography.Text>
                                </Space>

                                <Typography.Text className="footer-text">Designed by anasology - Powered by <a href="https://www.coinranking.com" target="_blank">CoinRanking</a> & <a href="https://www.lunarcrush.com" target="_blank">LunarCrush</a></Typography.Text>
                            </div>
                        }
                    </div>

                </GoogleOAuthProvider>
            </ConfigProvider>
        </HelmetProvider>
    )
}

export default App
