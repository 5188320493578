import {React, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Table, Avatar, Pagination} from 'antd';
import {NumericFormat} from 'react-number-format';

import Loader from './Loader';

import { getLunarTopCoins } from '../actions/lunarCoins'
import { getLocalCoins } from '../actions/localCoins';

//import { useGetListQuery } from '../services/cryptoQuery';
//changer la base de données des icones par coinlist/localcoins et prendre que les coins et champs qui intéressent

const TopCoins = () => {
    const dispatch = useDispatch();
    const topCoinsDataSource = []
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(0)
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getLunarTopCoins(limit, offset))
    }, [limit, offset], dispatch)
    

    useEffect(() => {
        dispatch(getLocalCoins(null, 250));
    }, [], dispatch)

    const {localCoins, isLoading} = useSelector((state) => state.reducers.localCoins); //prend trop de temps. à splitter éventuellement, faire requete icones
    const {lunarTopCoins, lunarTopCoinsLoading} = useSelector((state) => state.reducers.lunarCoins);


    if (lunarTopCoinsLoading === true) return <Loader />;
    if (isLoading) return <Loader />;

    lunarTopCoins?.forEach(element => {
        topCoinsDataSource.push({
          key: element?.current?.data[0]?.symbol,
          uuid: localCoins? localCoins?.filter(coin => coin.symbol === element.name)[0]?.uuid : '-',
          name: element?.current?.data[0]?.name,
          //changeIcon: element.coinData[period]?.change.tweets >= 0 ? <CaretUpFilled style={{ color: '#00FF00' }}/> : <CaretDownFilled style={{ color: '#FF0000' }}/>,
          icon: localCoins? localCoins?.filter(coin => coin.symbol === element.name)[0]?.iconUrl : '-',
          //icon: cryptosList ? cryptosList?.data?.coins?.filter(coin => coin.symbol == element.coinId.substring(1))[0]?.iconUrl : '-',
          interactions : element?.current?.data[0]?.interactions_24h,
          change : (element?.current?.data[0]?.interactions_24h - element?.previous?.data[0]?.interactions_24h) / element?.previous?.data[0]?.interactions_24h,
          socialVolume : element?.current?.data[0]?.social_volume_24h,
          //tweetsChange: element.coinData[period]?.change.tweets,
          //likes: element.coinData[period]?.agg.likes,
          //retweets: element.coinData[period]?.agg.retweets,
          //replies: element.coinData[period]?.agg.replies,
          //quotes: element.coinData[period]?.agg.quotes
        });
    })

    const topCoinsColumns = [
        {title: '', dataIndex: 'icon', key: 'icon', align:'center', render:icon => (<Avatar src={icon}/>), width : 30},
        {title: 'uuid', dataIndex: 'uuid', key: 'uuid', align:'center', width:30, hidden: true},
        {title: 'Coin', dataIndex: 'key', key: 'coin',align:'center', width:50},
        {title: 'Name', dataIndex: 'name', key: 'name',align:'center', width:150},
        {title: 'Interactions', dataIndex: 'interactions', key: 'interactions', align:'center', render:tweets => ( <NumericFormat value={tweets} displayType={'text'} thousandSeparator={true} decimalScale={0}/>), width : 150},
        {title: 'Change', dataIndex: 'change', key: 'change', align:'center', render:change => ( <NumericFormat value={change * 100} displayType={'text'} thousandSeparator={true} decimalScale={2} suffix={'%'}/>), width : 100},
        {title: 'Social Volume', dataIndex: 'socialVolume', key: 'socialVolume', align:'center', render:tweets => ( <NumericFormat value={tweets} displayType={'text'} thousandSeparator={true} decimalScale={0}/>), width : 100},
        //{title: 'Change', dataIndex: 'tweetsChange', key: 'tweetsChange', render:tweetsChange => ( <NumericFormat value={tweetsChange*100} displayType={'text'} suffix={'%'} thousandSeparator={false} decimalScale={2}/>), width : 50},
        //{title: 'Likes', dataIndex: 'likes', key: 'likes', render:likes => ( <NumericFormat value={likes} displayType={'text'} thousandSeparator={true} decimalScale={0}/>), sorter: (a, b) => a.tweets - b.tweets, width : 150},
        //{title: 'Retweets', dataIndex: 'retweets', key: 'retweets', render:retweets => ( <NumericFormat value={retweets} displayType={'text'} thousandSeparator={true} decimalScale={0}/>), sorter: (a, b) => a.tweets - b.tweets, width : 150},
        //{title: 'Replies', dataIndex: 'replies', key: 'replies', render:replies => ( <NumericFormat value={replies} displayType={'text'} thousandSeparator={true} decimalScale={0}/>), sorter: (a, b) => a.tweets - b.tweets, width : 150},
        //{title: 'Quotes', dataIndex: 'quotes', key: 'quotes', render:quotes => ( <NumericFormat value={quotes} displayType={'text'} thousandSeparator={true} decimalScale={0}/>), sorter: (a, b) => a.tweets - b.tweets, width : 150},
      ];

    return (
        <>
            <Helmet>
                <title>Top Coins - SoCoins</title>
                <meta name="description" content="Top social performing cryptocurrencies" />
                <meta name="keywords" content="crypto, cryptocurrencies, top, social, data, analysis" />
            </Helmet>
            <Row>
                <Col xl={24} xs={24}>
                    <Col className='crypto-list'>
                        {!lunarTopCoins ? <Loader/> : <Table className='top-coins-table' rowClassName = 'top-coins-table-row' dataSource={topCoinsDataSource} scroll={{ x: '1500px' }} columns={topCoinsColumns} pagination={false} onRow={(record, rowIndex) => { return {onClick: event => record.uuid ? navigate(`/CoinPage/${record.uuid}`) : navigate(`/topcoins`)}}} style={{cursor: "pointer"}}/>}
                    </Col>
                    <Col className='pagination-container'>
                        {<Pagination defaultCurrent={page} current={page} defaultPageSize={limit} total={100} className='pagination' onChange={(current, limit)=>{
                            setPage(current);
                            setLimit(limit);
                            setOffset(current*limit-limit);
                        }}
                        />}
                    </Col>
                </Col>
            </Row>
        </>
  )
}

export default TopCoins