import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation  } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Col, Row, Typography,Space, Segmented, Switch, Button, Tabs, Avatar, Table, Progress} from 'antd';
import { DollarCircleOutlined, TrophyOutlined, NumberOutlined, CalendarOutlined, StockOutlined, CheckCircleOutlined, TwitterOutlined, MessageOutlined, HeartFilled, RightOutlined, HeartOutlined, LikeOutlined, LikeFilled, CaretUpFilled, CaretDownFilled, UserOutlined, UserAddOutlined, FireOutlined } from '@ant-design/icons';
import millify from 'millify';
import {NumericFormat} from 'react-number-format';
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import HTMLReactParser from 'html-react-parser'

import LightWeightChart from './LightWeightChart';
import { WebSockets } from './WebSocket/webSockets'
import { useGetExchangesDetailsQuery, useGetOhlcQuery, useGetDetailsQuery, useGetHistoryQuery } from '../services/cryptoQuery';
import { getLocalCoins, likeLocalCoin } from '../actions/localCoins';
import {  getLunarCoinsList , getLunarCoinWeekV2, getLunarCoinYearV2 } from '../actions/lunarCoins';
import { FormatHistory, FormatOhlc, FormatOhlcMa } from './dataFormat';
import { MovingAverage } from './technicalIndicators';
import store from '../app/Store'
import Speedometer   from './speedometer';
import SocialChart from './SocialChart';
import Loader from './Loader';

/* Animation sympa enlevée pour utiliser millify mais à réutiliser
                                <TweenOne animation={{Children: { value: tweets? millify(tweets[key]?tweets[key]:0) : 'N/A', floatLength: 0, formatMoney: true }, duration: 500}}>
                                0
                                </TweenOne>
*/

//remplacer le sentiment par le galaxy score
//récupérer la social dominance depuis timeseries
//mettre le social rank en bas et rajouter new contributors en haut

const { Title, Text } = Typography;
const { TabPane } = Tabs //deprecated
TweenOne.plugins.push(Children);
var wsPrices = []
const exchangesDataSource = [] //à déplacer dans CoinPage sinon ça se recharge 2 fois

const CoinPage = () => {
  const { coinId } = useParams([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('profile'));
  const currency = useSelector((state) => state.reducers.setCurrency.value)

  //const [refresh, setRefresh] = useState(0);
  const [timePeriod, setTimePeriod] = useState('24h');
  const [ohlcInterval, setOhlcInterval] = useState('hour');
  const [maPeriod, setMaPeriod] = useState('25');
  const [tweetsPeriod, setTweetsPeriod] = useState('week');
  const [exponential, setExponential] = useState(false)
  const [socialChartSwitch, setSocialChartSwitch] = useState('posts')
  const [showPrice, setShowPrice] = useState(false)
  const [chartType, setChartType] = useState('Line')
  const [history, setHistory] = useState();
  const [ohlc, setOhlc] = useState();
  const [ohlcMa, setOhlcMa] = useState();
  const [showMa, setShowMa] = useState(false);
  const [showFibo, setShowFibo] = useState(false);
  const [tweetsChart, setTweetsChart] = useState();
  const [newPostsChart, setNewPostsChart] = useState();
  const [interactions, setInteractions] = useState();
  const [lunarPrice, setLunarPrice] = useState();
  const [tweets, setTweets] = useState();
  const [tweetsPrevious, setTweetsPrevious] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  const red = getComputedStyle(document.documentElement).getPropertyValue('--redDown')
  const green = getComputedStyle(document.documentElement).getPropertyValue('--greenUp')

  const chartTypeOptions = ['Line', 'Candles'];
  const time = ['1h','3h', '24h', '7d', '30d', '3m', '1y', '3y', '5y'];
  const ohlcTime = ['minute', 'hour', '8hours', 'day', 'week', 'month'];
  const maPeriods = ['7', '25', '50', '100'];
  const tweetsTimeFrame = ['day', 'week', 'month', 'year', 'all'];
  const tweetsTimeFrame2 = ['week', 'month', 'year', 'all'];

  const socialChartSwitchData = [
    { value: 'posts', icon: <TwitterOutlined /> },
    { value: 'interactions', icon: <HeartFilled /> },
  ]

  let ws = WebSockets(coinId)

  const wsPrices = useSelector((state) => state.reducers.webSockets?.history);

  /*const webSocketPrices = () => {
    const storeData = store.getState();
    const history = storeData?.reducers?.webSockets?.history;
    wsPrices = history;
  }*/

  const localCoin = useSelector((state) => state?.reducers?.localCoins);
  const {lunarCoinsList, lunarCoinWeekV2, lunarCoinYearV2, lunarWeekLoadingV2, lunarYearLoadingV2, lunarListLoading} = useSelector((state) => state.reducers.lunarCoins);

  const { data, isFetching : cryptoDetailsFetching } = useGetDetailsQuery({coinId, currency});
  const cryptoDetails = data?.data?.coin;
  const name = cryptoDetails?.symbol
  const lunarName = lunarCoinsList[0]?.current?.data[0]?.name.toLowerCase()
  const fullName = cryptoDetails?.name.toLowerCase()

  const { data : exchanges, isFetching : exchangesFetching } = useGetExchangesDetailsQuery(coinId);
  const exchangesDetails = exchanges?.data?.exchanges;

  exchangesDetails?.forEach(element => {
    exchangesDataSource.push({
      key: element.uuid,
      rank: element.rank,
      icon: element.iconUrl,
      name: element.name,
      price: element.price
    });
  })

  const exchangesDetailsColumns = [
    {title: 'Rank', dataIndex: 'rank', key: 'rank'},
    {title: 'Icon', dataIndex: 'icon', key: 'icon', render:icon => (<Avatar src={icon}/>)},
    {title: 'Name', dataIndex: 'name', key: 'name'},
    {title: 'Price', dataIndex: 'price', key: 'price', render:price => ( <NumericFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2}/>)}
  ];

  const { data: historyData, isFetching : historyFetching } = useGetHistoryQuery({coinId, timePeriod, currency});
  const { data: ohlcData, isFetching : ohlcFetching } = useGetOhlcQuery({coinId, ohlcInterval, currency});
  const { data: ohlcEightHours, isFetching : ohlcEightHoursFetching } = useGetOhlcQuery({coinId, ohlcInterval:'8hours', currency});
  const { data: ohlcOneDay, isFetching : ohlcOneDayFetching } = useGetOhlcQuery({coinId, ohlcInterval:'day', currency});
  const { data: ohlcOneWeek, isFetching : ohlcOneWeekFetching } = useGetOhlcQuery({coinId, ohlcInterval:'week', currency});
  const { data: ohlcOneMonth, isFetching : ohlcOneMonthFetching } = useGetOhlcQuery({coinId, ohlcInterval:'month', currency});

  const Likes = (modeData) => {
    const mode = modeData.data
    if (localCoin.localCoins[0]) { // erreur quand le site en idle : can not read property of undefined reading likes/fav 
      if (localCoin?.localCoins[0][mode]?.length > 0) {
        return localCoin?.localCoins[0][mode]?.find((like) => like === (user?.result?.sub || user?.result?._id))
          ? (
            mode === 'likes' ?
            <><LikeFilled/>&nbsp;{localCoin.localCoins[0][mode]?.length}</>
            :
            <HeartFilled />
          ) : (
            mode === 'likes' ?
            <><LikeOutlined/>&nbsp;{localCoin.localCoins[0][mode]?.length}</>
            :
            <HeartOutlined/>
          );
      }
      return mode === 'likes' ? <LikeOutlined/> : <HeartOutlined/>;

    }
  };

  /*
  useEffect(() => {
    var run = 0
    const interval = setInterval(() => {
      run += 1
      if (refresh < 30){ //mettre plutot idle
        setRefresh(refresh => refresh  + 1);
        webSocketPrices()
      }
      else if (refresh >= 30){
        clearInterval(interval);
      }
      }, 30000);
      return () => clearInterval(interval);
  }, [refresh]);
  */

  useEffect(() => {
    window.addEventListener("resize", () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);


  useEffect(() => {
    historyFetching? (<Loader/>) : (setHistory(FormatHistory(historyData)))
    ohlcFetching || ohlcEightHoursFetching || ohlcOneDayFetching || ohlcOneWeekFetching || ohlcOneMonthFetching ? (<Loader/>) : (setOhlc(FormatOhlc(ohlcData)))
    ohlcFetching || ohlcEightHoursFetching || ohlcOneDayFetching || ohlcOneWeekFetching || ohlcOneMonthFetching ? (<Loader/>) : (setOhlcMa(FormatOhlcMa(ohlcData)))
  }, [/*refresh,*/ location, historyData, ohlcData, historyFetching, ohlcFetching, ohlcEightHoursFetching, ohlcOneDayFetching, ohlcOneWeekFetching, ohlcOneMonthFetching]);
  
  useEffect(() => {
    dispatch(getLocalCoins(JSON.stringify({uuid : coinId})));
  }, [coinId], dispatch);

  useEffect(() => {
    if (name) {
      dispatch(getLunarCoinsList(name))
    }
    if (lunarName) {
      dispatch(getLunarCoinWeekV2(lunarName))
      dispatch(getLunarCoinYearV2(lunarName))//ça prend du temps à charger. voir comment changer le modele pour enregistrer seulement les champs qui m'intéressnet
    }

  }, [name, lunarName], dispatch);

  useEffect(() => {
    if (lunarWeekLoadingV2 === false &&  lunarCoinYearV2.length > 0 && fullName) {//changer par year après tests quand bucket day marche
      const lunarTweetstData = (lunarData, property, slice, limit) => {
        const data = lunarData[0]?.data?.slice(slice, limit).reduce(function (accumulator, object) {
          return object[property] ? accumulator + object[property] : accumulator;
        }, 0);
        return data
      }
      const lunarChartData = (lunarData, lunarParams) => {
        const data = lunarData[0]?.data?.map(function (element, n) {
          return {time : element.time, value : element[lunarParams]}
        })
        return data
      }

      const lunarTweets  = {
        'hour' : {'activePosts' : lunarCoinWeekV2.length > 0 ? lunarTweetstData(lunarCoinWeekV2, 'posts_active', -2) : 0, 'newPosts' : lunarCoinWeekV2.length > 0 ? lunarTweetstData(lunarCoinWeekV2, 'posts_created', -2) : 0, 'interactions' : lunarCoinWeekV2.length > 0 ? lunarTweetstData(lunarCoinWeekV2, 'interactions', -2):0, 'newContributors' : lunarCoinWeekV2.length > 0 ? lunarTweetstData(lunarCoinWeekV2, 'contributors_created', -2):0,  'activeContributors' : lunarCoinWeekV2.length > 0 ? lunarTweetstData(lunarCoinWeekV2, 'contributors_active', -2):0},
        'day' : {'activePosts' : lunarTweetstData(lunarCoinYearV2, 'posts_active', -2), 'newPosts' : lunarTweetstData(lunarCoinYearV2, 'posts_created', -2), 'interactions' : lunarTweetstData(lunarCoinYearV2, 'interactions', -2), 'newContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_created', -2), 'activeContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_active', -2)},
        'week' : {'activePosts' : lunarTweetstData(lunarCoinYearV2, 'posts_active', -8), 'newPosts' : lunarTweetstData(lunarCoinYearV2, 'posts_created', -8), 'interactions' : lunarTweetstData(lunarCoinYearV2, 'interactions', -8), 'newContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_created', -8), 'activeContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_active', -8)},
        'month' : {'activePosts' : lunarTweetstData(lunarCoinYearV2, 'posts_active', -31), 'newPosts' : lunarTweetstData(lunarCoinYearV2, 'posts_created', -31), 'interactions' : lunarTweetstData(lunarCoinYearV2, 'interactions', -31), 'newContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_created', -31), 'activeContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_active', -31)},
        'year' : {'activePosts' : lunarTweetstData(lunarCoinYearV2, 'posts_active', -365), 'newPosts' : lunarTweetstData(lunarCoinYearV2, 'posts_created', -365), 'interactions' : lunarTweetstData(lunarCoinYearV2, 'interactions', -365), 'newContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_created', -365), 'activeContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_active', -365)},
        'all' : {'activePosts' : lunarTweetstData(lunarCoinYearV2, 'posts_active', 0), 'newPosts' : lunarTweetstData(lunarCoinYearV2, 'posts_created', 0), 'interactions' : lunarTweetstData(lunarCoinYearV2, 'interactions', 0), 'newContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_created', 0), 'activeContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_active', 0)},
      }
      setTweets(lunarTweets[tweetsPeriod])

      const lunarTweetsPrevious  = {
        'hour' : {'activePosts' : lunarCoinWeekV2.length > 0 ? lunarTweetstData(lunarCoinWeekV2, 'posts_active', -3) : 0, 'newPosts' : lunarCoinWeekV2.length > 0 ? lunarTweetstData(lunarCoinWeekV2, 'posts_created', -3) : 0, 'likes' : lunarCoinWeekV2.length > 0 ? lunarTweetstData(lunarCoinWeekV2, 'interactions', -3):0, 'contributors' : lunarCoinWeekV2.length > 0 ? lunarTweetstData(lunarCoinWeekV2, 'contributors_created', -3):0,  'socialRank' : lunarCoinWeekV2.length > 0 ? lunarTweetstData(lunarCoinWeekV2, 'alt_rank', -3):0},
        'day' : {'activePosts' : lunarTweetstData(lunarCoinYearV2, 'posts_active', -3, -2), 'newPosts' : lunarTweetstData(lunarCoinYearV2, 'posts_created', -3, -2), 'interactions' : lunarTweetstData(lunarCoinYearV2, 'interactions', -3, -2), 'newContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_created', -3, -2), 'activeContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_active', -3, -2)}, //vérifire les plages
        'week' : {'activePosts' : lunarTweetstData(lunarCoinYearV2, 'posts_active', -15, -8), 'newPosts' : lunarTweetstData(lunarCoinYearV2, 'posts_created', -15, -8), 'interactions' : lunarTweetstData(lunarCoinYearV2, 'interactions', -15, -8), 'newContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_created', -15, -8), 'activeContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_active', -15, -8)}, //vérifire les plages
        'month' : {'activePosts' : lunarTweetstData(lunarCoinYearV2, 'posts_active', -61, -31), 'newPosts' : lunarTweetstData(lunarCoinYearV2, 'posts_created', -61, -31), 'interactions' : lunarTweetstData(lunarCoinYearV2, 'interactions', -61, -31), 'newContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_created', -61, -31), 'activeContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_active', -61, -31)}, //vérifire les plages
        'year' : {'activePosts' : lunarTweetstData(lunarCoinYearV2, 'posts_active', -730, -365), 'newPosts' : lunarTweetstData(lunarCoinYearV2, 'posts_created', -730, -365), 'interactions' : lunarTweetstData(lunarCoinYearV2, 'interactions', -730, -365), 'newContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_created', -730, -365), 'activeContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_active', -730, -365)}, //vérifire les plages
        'all' : {'activePosts' : lunarTweetstData(lunarCoinYearV2, 'posts_active', 0), 'newPosts' : lunarTweetstData(lunarCoinYearV2, 'posts_created', 0), 'interactions' : lunarTweetstData(lunarCoinYearV2, 'interactions', 0), 'newContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_created', 0), 'activeContributors' : lunarTweetstData(lunarCoinYearV2, 'contributors_active', 0)}, //Enlever ?
      }
      setTweetsPrevious(lunarTweetsPrevious[tweetsPeriod])

      const lunarChart = {'day' : lunarCoinWeekV2.length > 0 ? lunarChartData(lunarCoinWeekV2, 'posts_active').slice(-24):0, 'week': lunarCoinWeekV2.length > 0 ? lunarChartData(lunarCoinWeekV2, 'posts_active'):lunarChartData(lunarCoinYearV2, 'posts_active').slice(-7), 'month' : lunarChartData(lunarCoinYearV2, 'posts_active').slice(-30), 'year' : lunarChartData(lunarCoinYearV2, 'posts_active').slice(-365), 'all' : lunarChartData(lunarCoinYearV2, 'posts_active')}
      const newPosts = {'day' : lunarCoinWeekV2.length > 0 ? lunarChartData(lunarCoinWeekV2, 'posts_created').slice(-24):0, 'week': lunarCoinWeekV2.length > 0 ? lunarChartData(lunarCoinWeekV2, 'posts_created'):lunarChartData(lunarCoinYearV2, 'posts_created').slice(-7), 'month' : lunarChartData(lunarCoinYearV2, 'posts_created').slice(-30), 'year' : lunarChartData(lunarCoinYearV2, 'posts_created').slice(-365), 'all' : lunarChartData(lunarCoinYearV2, 'posts_created')}
      const interactionsData = {'day' : lunarCoinWeekV2.length > 0 ? lunarChartData(lunarCoinWeekV2, 'interactions').slice(-24):0, 'week': lunarCoinWeekV2.length > 0 ? lunarChartData(lunarCoinWeekV2, 'interactions'):lunarChartData(lunarCoinYearV2, 'interactions').slice(-7), 'month' : lunarChartData(lunarCoinYearV2, 'interactions').slice(-30), 'year' : lunarChartData(lunarCoinYearV2, 'interactions').slice(-365), 'all' : lunarChartData(lunarCoinYearV2, 'interactions')}
      const price = {'day' : lunarCoinWeekV2.length > 0 ? lunarChartData(lunarCoinWeekV2, 'close').slice(-24):0, 'week': lunarCoinWeekV2.length > 0 ? lunarChartData(lunarCoinWeekV2, 'close'):lunarChartData(lunarCoinYearV2, 'close').slice(-7), 'month' : lunarChartData(lunarCoinYearV2, 'close').slice(-30), 'year' : lunarChartData(lunarCoinYearV2, 'interactions').slice(-365), 'all' : lunarChartData(lunarCoinYearV2, 'close')}
      setTweetsChart(lunarChart[tweetsPeriod])
      setNewPostsChart(newPosts[tweetsPeriod])
      setInteractions(interactionsData[tweetsPeriod])
      setLunarPrice(price[tweetsPeriod])

    }
  }, [lunarWeekLoadingV2, lunarYearLoadingV2, fullName, tweetsPeriod]);
  
  if (cryptoDetailsFetching) return <Loader />;

  const generalStats = [
    { key : 'listed', title: 'Listed', value: new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(cryptoDetails?.listedAt*1000), icon: <CalendarOutlined /> },
    { key : 'volume', title: '24h Volume', value: `$ ${isNaN(cryptoDetails['24hVolume']) || !cryptoDetails['24hVolume'] ? ("-") : (millify(cryptoDetails['24hVolume']))}`, icon: <StockOutlined /> },
    { key : 'marketCap', title: 'Market Cap', value: `$ ${cryptoDetails.marketCap && millify(cryptoDetails.marketCap)}`, icon: <DollarCircleOutlined /> },
    { key : 'ath', title: 'ATH', value: <NumericFormat value={cryptoDetails.allTimeHigh.price} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2}/>, icon: <TrophyOutlined />},
    { key : 'supply', title: 'Supply', value: `${cryptoDetails?.supply?.circulating  === null || cryptoDetails?.supply?.circulating  === 'undefined' ? ("-") : (millify(cryptoDetails?.supply?.circulating))} / ${cryptoDetails?.supply?.total  === null || cryptoDetails?.supply?.total  === 'undefined' ? ("-") : (millify(cryptoDetails?.supply?.total))}`, icon: <CheckCircleOutlined /> },
    { key : 'rank', title: 'Rank', value: cryptoDetails.rank, icon: <NumberOutlined /> },
  ];

  const supplyStats = [
    { key : 'confirmed', title: 'Confirmed', value: `${cryptoDetails?.supply?.confirmed  === null || cryptoDetails?.supply?.confirmed  === 'undefined' ? ("-") : (cryptoDetails?.supply?.confirmed)}`, icon: <CheckCircleOutlined /> },
    { key : 'total', title: 'Total',value: `${cryptoDetails?.supply?.total  === null || cryptoDetails?.supply?.total  === 'undefined' ? ("-") : (millify(cryptoDetails?.supply?.total))}`, icon: <CheckCircleOutlined /> },
    { key : 'circulation', title: 'Circulating', value: `${cryptoDetails?.supply?.circulating  === null || cryptoDetails?.supply?.circulating  === 'undefined' ? ("-") : (millify(cryptoDetails?.supply?.circulating))}`, icon: <CheckCircleOutlined /> },
    { key : 'max', title: 'Max', value: `${cryptoDetails?.supply?.max  === null || cryptoDetails?.supply?.max  === 'undefined' ? ("-") : (millify(cryptoDetails?.supply?.max))}`, icon: <CheckCircleOutlined /> }
  ];
  
  const twitterStats = [
    { title: 'Active Posts', key: 'activePosts', icon: <TwitterOutlined style={{ fontSize: '16px', color: '#1DA1F2' }}/> },
    { title: 'New Posts', key: 'newPosts', icon: <MessageOutlined style={{ fontSize: '16px', color: '#1DA1F2' }}/> },
    { title: 'Interactions', key: 'interactions', icon: <TweenOne animation={[{x:0, y:0, z:0}, { scaleX: 1.2, scaleY: 1.2, scaleZ: 1}]} repeat={-1} ease={'easeOutElastic'}><HeartFilled style={{ fontSize: '16px', color: '#c41d7f'}}/></TweenOne> },
    { title: 'Active users', key: 'activeContributors', icon: <UserOutlined style={{ fontSize: '16px', color: '#1DA1F2' }}/> },
    { title: 'New users', key: 'newContributors', icon: <UserAddOutlined style={{ fontSize: '16px', color: '#1DA1F2' }} /> },
  ];

  const twitterStats2 = [
    { title: 'Hour', key: 'hour', icon: <RightOutlined style={{ fontSize: '16px', color: '#FFF' }}/> },
    { title: 'Day', key: 'day', icon: <RightOutlined style={{ fontSize: '16px', color: '#FFF' }}/> },
    { title: 'Month', key: 'month', icon: <RightOutlined style={{ fontSize: '16px', color: '#FFF' }}/> },
  ];

  const noTwitterStats = [
    { title: 'Interactions', key: 'interactions_24h', icon: <TweenOne animation={[{x:0, y:0, z:0}, { scaleX: 1.2, scaleY: 1.2, scaleZ: 1}]} repeat={-1} ease={'easeOutElastic'}><HeartFilled style={{ fontSize: '16px', color: '#1DA1F2'}}/></TweenOne> },
    { title: 'Social Volume', key: 'social_volume_24h', icon: <TwitterOutlined style={{ fontSize: '16px', color: '#1DA1F2' }}/> },
    { title: 'Social Score', key: 'galaxy_score', icon:  <FireOutlined style={{ fontSize: '16px', color: '#1DA1F2' }}/> },
    { title: 'Social Rank', key: 'alt_rank', icon: <TrophyOutlined style={{ fontSize: '16px', color: '#1DA1F2' }}/> },
  ];

  if (!ohlcData || !ohlcEightHours || !ohlcOneDay || !ohlcOneWeek || !ohlcOneMonth) return <Loader />;

  const movingAverageDataSource = [
    {key:'1', type:'MA 1 hour', value:MovingAverage({exponential:exponential, data:FormatOhlcMa(ohlcData), period:maPeriod, history:false})},
    {key:'2', type:'MA 8 hours', value:MovingAverage({exponential:exponential, data:FormatOhlcMa(ohlcEightHours), period:maPeriod, history:false})},
    {key:'3', type:'MA 1 day', value:MovingAverage({exponential:exponential, data:FormatOhlcMa(ohlcOneDay), period:maPeriod, history:false})},
    {key:'4', type:'MA 1 week', value:MovingAverage({exponential:exponential, data:FormatOhlcMa(ohlcOneWeek), period:maPeriod, history:false})},
    {key:'5', type:'MA 1 month', value:MovingAverage({exponential:exponential, data:FormatOhlcMa(ohlcOneMonth), period:maPeriod, history:false})},
  ]

  return (
    <>
      <Helmet>
        <title>Crypto Page - SoCoins</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Row gutter={[16]} className="crypto-heading-container">
        <Col xl={14} xs={24} className="crypto-heading-left">
          <Row>
            <Col xl={20} sm ={20} xs={24} className="crypto-heading">
              <Row>
                <img className="crypto-logo" alt="" src={cryptoDetails?.iconUrl}></img>
                <Text className="crypto-name">{cryptoDetails?.name}</Text>
                <Text className="crypto-symbol">${cryptoDetails?.symbol}</Text>
                {cryptoDetails?.change >= 0 ? <CaretUpFilled className="crypto-arrow" style={{ color: green }}/> : <CaretDownFilled className="crypto-name" style={{ color: red }}/>}
                <Text className={cryptoDetails?.change >= 0 ? "crypto-changeup" : "crypto-changedown"}>{cryptoDetails?.change <= 0 ? cryptoDetails?.change : `+${cryptoDetails?.change}`}%</Text>
                <Text className="crypto-price"><span className="crypto-price-font">$<span>&nbsp;</span>
                  {currency === 'HIVsRcGKkPFtW' ? !wsPrices[coinId]?.price? (Number(cryptoDetails?.price).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})) : (Number(wsPrices[coinId]?.price).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})) : (Number(cryptoDetails?.price).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})) }</span>
                </Text>
              </Row>
            </Col>
            <Col xl={2} sm ={2} xs={12} className="crypto-like-container">
              <Button className={!isMobile? "like-button" : "like-button-mobile"} key="like" disabled={!user?.result} onClick={() => dispatch(likeLocalCoin(coinId,"likes"))}><Likes data={'likes'}/></Button>
            </Col>
            <Col xl={2} sm ={2} xs={12} className="crypto-fav-container">
              <Button className={!isMobile? "fav-button" : "fav-button-mobile"} key="like" disabled={!user?.result} onClick={() => dispatch(likeLocalCoin(coinId,"fav"))}><Likes data={'fav'}/></Button>
            </Col>
          </Row>
          
          <Row>
            <Col xl={12} xs={12} className="chart-options-container">
              {chartType === 'Line' ? (
                <Col className="chart-timeperiod-container">
                  <Segmented
                    size="small"
                    className="chart-timeperiod"
                    id="data-selector"
                    value={timePeriod}
                    options={time}
                    onChange={setTimePeriod}
                  />
                </Col>) : ('')
              }

              {chartType === 'Candles' ? (
                <Col className="chart-interval-container">
                  <Segmented
                    size="small"
                    className="chart-interval"
                    id="data-selector"
                    value={ohlcInterval}
                    options={ohlcTime}
                    onChange={setOhlcInterval}
                  />
                </Col>) : ('')
              }
            </Col>
      
            <Col xl={12} xs={12} className="chart-options-container">
              <Col className="chart-type-container">
                <Segmented
                  size="small"
                  className="chart-type"
                  value={chartType}
                  options={chartTypeOptions}
                  onChange={setChartType}
                />
              </Col>
            </Col>

          </Row>
        </Col>
        
        <Col xl={10} xs={24}>
        </Col>  

      </Row>

      <Row gutter={[16]} className="crypto-main-container">

        <Col xl={14} xs={24} className="crypto-chart">  

          {!history || !ohlc ? (<Loader />) : (
            <LightWeightChart data = {history} ohlcData = {ohlc} ohlcMaData = {ohlcMa} coinName={cryptoDetails?.name} chartType = {chartType} exponential = {exponential} maPeriod = {maPeriod} showMa = {showMa} showFibo = {showFibo} className="crypto-chart"></LightWeightChart>
          )}

          <Row>
            <Col xl={24} xs={24} className="chart-technical-container">
              <Space>
              <b id="technical-title">Moving Average :</b>
              <Switch id="technical-button" checked={showMa} size="small" onChange={setShowMa}/>
              <Segmented
                  size="small"
                  id="data-selector"
                  value={maPeriod}
                  options={maPeriods}
                  onChange={setMaPeriod}
              />
              </Space>
              <div id="exponential">
                <Space>
                  <span id="technical-title">Exponential MA :</span>
                  <Switch size="small" checked={exponential} id="technical-button" onChange={setExponential}/>
                </Space>  
              </div>

            </Col>

          </Row>
          
          <Tabs>
            <TabPane tab="General Stats" key="tab-a">
              <Row className="crypto-hilights-container">
                {generalStats.map(({key, icon, title, value}) => (
                  <Col xl={4} xs={8} className="crypto-hilights" key={key}>
                    <Col className="crypto-hilights-name">
                      <Space>
                        <Text id="hilights-icon">{icon}</Text>
                        <Text id="hilights-text">{title}</Text>
                      </Space>
                    </Col>
                    <Col className="crypto-hilights-value">
                      <Text>{value}</Text>
                    </Col>
                  </Col>
                ))}
              </Row>
            </TabPane>
            <TabPane tab="Supply" key="tab-b">
              <Row className="crypto-hilights-container">
                {supplyStats.map(({key, icon, title, value}) => (
                  <Col xl={6} xs={12} className="crypto-hilights" key={key}>
                    <Col className="crypto-hilights-name">
                      <Space>
                        <Text id="hilights-icon">{icon}</Text>
                        <Text id="hilights-text">{title}</Text>
                      </Space>
                    </Col>
                    <Col className="crypto-hilights-value">
                      <Text>{value}</Text>
                    </Col>
                  </Col>
                ))}
              </Row>
            </TabPane>
            <TabPane tab="Exchanges" key="tab-c">
              <Table dataSource={exchangesDataSource} columns={exchangesDetailsColumns} />;
            </TabPane>
            <TabPane tab="Description" key="tab-d">
              <Row className="crypto-hilights-container">
                  <Col xl={24} xs={24} className="crypto-hilights">
                    <Col className="crypto-hilights-name">
                      <Space>
                        <Text id="hilights-icon"><TrophyOutlined/></Text>
                        <Text>Description</Text>
                      </Space>
                    </Col>
                    <Col className="crypto-hilights-value">
                      {cryptoDetails?.description ? HTMLReactParser(cryptoDetails?.description) : 'No description available'}
                    </Col>
                  </Col>
              </Row>
            </TabPane>
          </Tabs>

        </Col>

        <Col xl={10} xs={24} className='TBD'>
          <Col className="crypto-analysis-container">
            <Col className="crypto-analysis-borders">

              <Col className="crypto-analysis-social-container">
                <Col className="crypto-analysis-heading">
                  <Title level={4} className="crypto-analysis-title">
                    ${cryptoDetails.symbol} Social Mentions    
                  </Title>                    
              </Col>
                
              {lunarCoinYearV2[0]?.name === lunarName ? //il faut rajouter le nom de la crypto dans le reducer ensuite vérifier si nom = nom
                <>
                  <Row className='crypto-social-item-container'>
                    <Col xl={12} sm={12} xs={24} className="crypto-social-selector">
                      {lunarCoinWeekV2[0]?.name === lunarName ?
                        <Segmented
                          size="small"
                          className="social-timeperiod"
                          id="data-selector"
                          value={tweetsPeriod}
                          options={tweetsTimeFrame}
                          onChange={setTweetsPeriod}
                        />
                        :
                        <Segmented
                          size="small"
                          className="social-timeperiod"
                          id="data-selector"
                          value={tweetsPeriod}
                          options={tweetsTimeFrame2}
                          onChange={setTweetsPeriod}
                        />
                      }
                    </Col>
                    <Col xl={12} sm={12} xs={24} className="crypto-social-selector">
                      <Segmented
                          size="small"
                          id="data-selector"
                          className="social-chart-switch"
                          value={socialChartSwitch}
                          options={socialChartSwitchData}
                          onChange={setSocialChartSwitch}
                      />
                      <Space>
                        <span className="social-show-price" id="technical-title">show price :</span>
                        <Switch size="small" className="social-show-price" id="technical-button" onChange={setShowPrice}/>
                      </Space>  
                    </Col>
                  </Row>    
                  <Row type="flex" className='crypto-social-item-container'>
                    <Col xl={12} sm={12} xs={24} className="crypto-social-item">
                      {lunarCoinWeekV2[0]?.name === lunarName ?
                        <Segmented
                          size="small"
                          className="social-timeperiod-mobile"
                          id="data-selector"
                          value={tweetsPeriod}
                          options={tweetsTimeFrame}
                          onChange={setTweetsPeriod}
                        />
                        :
                        <Segmented
                          size="small"
                          className="social-timeperiod-mobile"
                          id="data-selector"
                          value={tweetsPeriod}
                          options={tweetsTimeFrame2}
                          onChange={setTweetsPeriod}
                        />
                      }
                      {lunarWeekLoadingV2 && lunarYearLoadingV2 ? (
                        twitterStats?.map(({icon, title, key}) => (
                          <Col className="social-stats" key={key} >
                            <Col className="social-stats-name">
                              <Text>{icon}</Text>
                              <Text>{title}</Text>
                            </Col>
                            <Col><Text className="social-stats-value">NA</Text></Col>
                            <Col><Text className="social-stats-change">NA</Text></Col>
                          </Col>
                        ))) : (
                        twitterStats?.map(({icon, title, key}) => (
                          <Col className="social-stats" key={key} >
                            <Col className="social-stats-name">
                              <Text className={!isMobile? "social-stats-icon-text" : "social-stats-icon-text-mobile"}>{icon}</Text>
                              <Text className={!isMobile? "social-stats-name-text" : "social-stats-name-text-mobile"}>{title}</Text>
                            </Col>
                            <Col className="social-stats-value">
                              <Text className={!isMobile? "social-stats-value-text" : "social-stats-value-text-mobile"}>
                                {!tweets && !tweetsPrevious ? 'N/A' : millify(tweets[key]?tweets[key]:0)}
                              </Text>
                            </Col>
                            <Col className={!tweets && !tweetsPrevious ? 'social-stats-change' : (tweets[key] - tweetsPrevious[key])/ tweetsPrevious[key] > 0 ? "social-stats-change-positive" : "social-stats-change-negative"}>
                              <Text className={!isMobile? "social-stats-change-text" : "social-stats-change-text-mobile"}>
                                <NumericFormat value={!tweets && !tweetsPrevious ? 'N/A' : ((tweets[key] - tweetsPrevious[key])/ tweetsPrevious[key]) * 100} displayType={'text'} decimalScale={!tweets && !tweetsPrevious ? 'N/A' : ((tweets[key] - tweetsPrevious[key])/ tweetsPrevious[key]) > 1 ? 0 : 2} suffix={'%'} />
                              </Text>
                            </Col>
                          </Col>
                        )))
                      }                  
                    </Col>

                    <Col xl={12} sm={12} xs={24} className="crypto-social-item">
                      <Col>
                        <Segmented
                            size="small"
                            id="data-selector"
                            className="social-chart-switch-mobile"
                            value={socialChartSwitch}
                            options={socialChartSwitchData}
                            onChange={setSocialChartSwitch}
                        />
                        <Space>
                          <span  className="social-show-price-mobile" id="technical-title">show price :</span>
                          <Switch size="small" className="social-show-price-mobile" id="technical-button" onChange={setShowPrice}/>
                        </Space>  
                      </Col>  
                      <Col>
                        {!tweetsChart ? ('') :  (<SocialChart activePosts={tweetsChart} newPosts={newPostsChart} interactions={interactions} showPrice={showPrice} price={lunarPrice} socialChartSwitch={socialChartSwitch}/>)}
                      </Col>
                    </Col>

                  </Row>
                </>
                :
                lunarCoinsList[0]?.name === name ?
                  <Row type="flex" className='crypto-social-item-container'>
                    <Col xl={24} xs={24} className="crypto-social-item">
                      {noTwitterStats?.map(({icon, title, key}) => (
                        <Col className="social-stats" key={key} >
                          <Col className="social-stats-name">
                            <Text>{icon}</Text>
                            <Text>{title}</Text>
                          </Col>
                          <Col className="social-stats-value">
                            <Text>
                              <TweenOne animation={{Children: { value: lunarCoinsList? lunarCoinsList[0]?.current?.data[0][key] : 'N/A', floatLength: 0, formatMoney: true }, duration: 500}}>
                              0
                              </TweenOne>
                            </Text>
                          </Col>
                          <Col className={!lunarCoinsList ? 'social-stats-change' : (lunarCoinsList[0]?.current?.data[0][key] - lunarCoinsList[0]?.previous?.data[0][key])/ lunarCoinsList[0].current.data[0][key] > 0 ? "social-stats-change-positive" : "social-stats-change-negative"} id={!lunarCoinsList  ? 'social-stats-change' : (lunarCoinsList[0].current.data[0][key] - lunarCoinsList[0].previous.data[0][key])/ lunarCoinsList[0].current.data[0][key] > 0 ? "twitter-values-positive" : "twitter-values-negative"}>
                            <Text className='social-stats-change-text'>
                              <NumericFormat value={lunarCoinsList ? (lunarCoinsList[0]?.current?.data[0][key] - lunarCoinsList[0]?.previous?.data[0][key])/ lunarCoinsList[0]?.current?.data[0][key] * 100 : "N/A"} displayType={'text'} decimalScale={2} suffix={'%'} />
                            </Text>
                          </Col>
                        </Col>
                      ))}
                    </Col>
                  </Row>
                :
                'not available'
              }

                {lunarListLoading? (
                  <>
                  <Row type="flex" className='crypto-social-item-container'>
                    <Col xl={3} xs={3}>Sentiments :</Col>
                    <Col xl={9} xs={9}>
                      loading
                    </Col>
                    <Col xl={6} xs={6} className='lunar-stats'>Volume</Col>
                    <Col xl={6} xs={6} className='lunar-stats'>Dominance</Col>
                  </Row>
                  </>
                  ) :  (
                  <Row type="flex" className='crypto-social-item-container' >
                    <Col xl={12} xs={12} className='lunar-stats'>
                      <Typography.Text strong>Global Sentiment</Typography.Text>
                      <Progress percent={Number(lunarCoinsList ? lunarCoinsList[0]?.current?.data[0]?.galaxy_score : 0).toFixed()} status="active" strokeColor={{ from: '#ffadd2', to: '#73d13d' }} />
                    </Col>
                    {/*
                    <Col xl={6} xs={6}>
                      <Col xl={24} xs={24} className='lunar-stats' id='lunar-social-volume'><b>Social Volume</b></Col>
                      <Col xl={24} xs={24} className='lunar-stats' id='lunar-social-volume'><NumericFormat value={lunarCoinsList ? lunarCoinsList[0]?.current?.data[0]?.social_volume_24h : '-'} displayType={'text'} thousandSeparator={true} decimalScale={0}/></Col>
                    </Col>
                    <Col xl={6} xs={6} id='social-dominance'>
                      <Col xl={24} xs={24} className='lunar-stats' id='lunar-social-dominance'><b>Social Dominance</b></Col>
                      <Col xl={24} xs={24} className='lunar-stats' id='lunar-social-dominance'><NumericFormat value={lunarCoinsList ? lunarCoinsList[0]?.current?.data[0]?.social_dominance : 0} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={0}/></Col>
                    </Col>
                    */}
                    <Col xl={12} xs={12} className="copyright-lunarcrush">
                      <Text></Text>
                      <a href="https://www.lunarcrush.com" target="_blank"></a>
                    </Col>
                  </Row>
                  )
                }
              </Col>

              <Col className="crypto-analysis-technical-container">
                <Col className="crypto-analysis-heading">
                  <Title level={4} className="crypto-analysis-title">
                    Not Financial Advices
                  </Title>                    
                </Col>
                <Row className='crypto-technical-item-container'>
                  <Col xl={12} sm={12} xs={24} className="crypto-technical-item-heading">
                    <Title className="crypto-technical-item-title" level={5}><Switch className="technical-ma-switch" id="technical-button" size="small" checked={showMa} onChange={setShowMa}/> Moving Average</Title>
                    <Space>
                      <Segmented
                          size="small"
                          className="technical-ma-time-period"
                          id="data-selector"
                          value={maPeriod}
                          options={maPeriods}
                          onChange={setMaPeriod}
                      />
                      <span className="technical-exp-switch">Exp :</span><Switch size="small" checked={exponential} className="technical-exp-switch" id="technical-button" onChange={setExponential}/>
                    </Space>
                  </Col>
                  <Col xl={12} sm={12} xs={24} className="crypto-technical-item-heading">
                    <Title className="crypto-technical-item-title" level={5}> <Switch size="small" className="technical-fibo-switch" id="technical-button" onChange={setShowFibo}/> Fibonacci {chartType === 'Line'? (timePeriod) : (ohlcInterval)}</Title>
                    {chartType === 'Line' ? (
                      <Segmented
                        size="small"
                        className="fibonacci-data-selector"
                        id="data-selector"
                        value={timePeriod}
                        options={time}
                        onChange={setTimePeriod}
                      />
                      ) : ('')
                    }

                    {chartType === 'Candles' ? (
                      <Segmented
                        size="small"
                        className="fibonacci-data-selector"
                        id="data-selector"
                        value={ohlcInterval}
                        options={ohlcTime}
                        onChange={setOhlcInterval}
                      />
                    ) : ('')
                    }
                  </Col>
                </Row>

                <Row className='crypto-technical-item-container'>
                  <Col xl={12} sm={12} xs={24} className="crypto-technical-item">
                    <Title className="crypto-technical-item-title-mobile" level={5}><Switch className="technical-ma-switch-mobile" id="technical-button" size="small" checked={showMa} onChange={setShowMa}/> Moving Average</Title>
                    <Space>
                      <Segmented
                          size="small"
                          className="technical-ma-time-period-mobile"
                          id="data-selector"
                          value={maPeriod}
                          options={maPeriods}
                          onChange={setMaPeriod}
                      />
                      <span className="technical-exp-switch-mobile">Exp :</span><Switch size="small" checked={exponential} className="technical-exp-switch-mobile" id="technical-button" onChange={setExponential}/>
                    </Space>
                    {movingAverageDataSource?.map(({key, type, value}) => (
                      <Col className="technical-stats" key={key} >
                        <Col xl={14} xs={14} className="technical-stats-name">
                          <Text>{type}</Text>
                        </Col>
                        <Col xl={10} xs={10} className={value.props.value < cryptoDetails?.price ? "technical-stats-change-positive" : "technical-stats-change-negative"}>
                          <Text className='social-stats-change-text'>{value}</Text>
                        </Col>
                      </Col>
                    ))}

                  </Col>
                  <Col xl={12} sm={12} xs={24} className="crypto-technical-item">
                    <Title className="crypto-technical-item-title-mobile" level={5}> <Switch size="small" className="technical-fibo-switch-mobile" id="technical-button" onChange={setShowFibo}/> Fibonacci {chartType === 'Line'? (timePeriod) : (ohlcInterval)}</Title>
                    {chartType === 'Line' ? (
                      <Segmented
                        size="small"
                        className="fibonacci-data-selector-mobile"
                        id="data-selector"
                        value={timePeriod}
                        options={time}
                        onChange={setTimePeriod}
                      />
                      ) : ('')
                    }

                    {chartType === 'Candles' ? (
                      <Segmented
                        size="small"
                        className="fibonacci-data-selector-mobile"
                        id="data-selector"
                        value={ohlcInterval}
                        options={ohlcTime}
                        onChange={setOhlcInterval}
                      />
                    ) : ('')
                    }
                    {chartType === 'Line' ? (                      
                      !history ? (<Loader />) :  (<Speedometer data={history}/>)                         
                      ) : ('')}
                    {chartType === 'Candles' ? (
                      !history ? (<Loader />) :  (<Speedometer data={ohlcMa}/>)                             
                      ) : ('')}

                  </Col>
                </Row>
              </Col>
            </Col>
          </Col>
        </Col> 
      </Row>
      
    </>  
  )

}

export default CoinPage