import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {Row, Col, Table, Button, Form, AutoComplete} from 'antd';
import {NumericFormat} from 'react-number-format';
import millify from 'millify';
import {fetchSuggestions} from '../services/fetchSuggestions';
import { useGetDetailsQuery, useGetHistoryQuery } from '../services/cryptoQuery';
import { FormatHistory} from './dataFormat';
import LightWeightChartx from './LightWeightChartx';
import Loader from './Loader';

const {Option} = AutoComplete;

var indexData = []

const CryptoIndex = () => {
    const currency = useSelector((state) => state.reducers.setCurrency.value)
    const [coinId, setCoinId] = useState('Qwsogvtv82FCd')
    const [counter, setCounter] = useState(0)
    const [suggestions, setSuggestions] = useState();
    const [timeScale, setTimeScale] = useState();
    const [form] = Form.useForm();
    const [index, setIndex] = useState()

    const {data, isFetching} = useGetDetailsQuery(coinId ? {coinId, currency} : {coinId : 'Qwsogvtv82FCd', currency});
    const { data: historyData, isFetching : historyFetching } = useGetHistoryQuery(coinId ? {coinId, timePeriod : '1y', currency} : {coinId : 'Qwsogvtv82FCd', timePeriod : '1y', currency});
    const history = {coin : coinId, data : historyFetching ? 'loading' : FormatHistory(historyData)}

    const [dataSource, setDataSource] = useState()

    useEffect(() => {

        var localCounter = counter

        if (coinId == 'Qwsogvtv82FCd' && counter == 0) {
            const timeSerie = history?.data == 'loading' ? 'loading' : history?.data?.map(function(timeStamp) {
                return {value : 0, time : timeStamp.time}
            })
            setTimeScale(timeSerie)
        }

        if (data?.data?.coin?.uuid == coinId && history?.coin == coinId && !historyFetching) {
            if (!dataSource){
                const newData = {
                    key: data?.data?.coin?.uuid,
                    line: counter+1,
                    name: isFetching ? 'loading' : data?.data?.coin?.symbol,
                    price: isFetching ? 'loading' : data?.data?.coin?.price,
                    marketCap: isFetching ? 'loading' : data?.data?.coin?.marketCap,
                    history : historyFetching ? 'loading' : history?.data
                };
                localCounter = localCounter + 1 
                if (localCounter <= 10 ) {
                    dataSource ? setDataSource([...dataSource, newData]) : setDataSource([newData]);
                    setCounter(counter+1)
                }
                setCoinId()
            } else {
                if (dataSource?.map(function(crypto) { return crypto.key}).indexOf(coinId)<0 ) {
                    const newData = {
                        key: data?.data?.coin?.uuid,
                        line: counter+1,
                        name: isFetching ? 'loading' : data?.data?.coin?.symbol,
                        price: isFetching ? 'loading' : data?.data?.coin?.price,
                        marketCap: isFetching ? 'loading' : data?.data?.coin?.marketCap,
                        history : historyFetching ? 'loading' : history?.data    //ne load pas
                    };
                    localCounter = localCounter + 1 
                    if (localCounter <= 10 ) {
                        dataSource ? setDataSource([...dataSource, newData]) : setDataSource([newData]);
                        setCounter(counter+1)
                    }
                    setCoinId()
                }
            }
        }
        }, [isFetching, historyFetching, coinId, history]);

        useEffect(() => {
            const totalMarketCap = dataSource?.reduce(function (accumulator, object) {return accumulator + Number(object['marketCap'])}, 0)
            const totalPrices = dataSource?.reduce(function (accumulator, object) {return accumulator + (Number(object.history[0]['value'])*Number(object['marketCap']))/totalMarketCap}, 0)
            dataSource?.map(function(datax) {
                datax['weight'] = (datax.marketCap / totalMarketCap) * 100
            })
            indexData = []
            if (timeScale && timeScale !== 'loading') {
                const timeScaleCopy = JSON.parse(JSON.stringify(timeScale));
                indexData = timeScaleCopy.map(function(timeElement){
                    const baseData = dataSource?.map(function(element){
                        element?.history?.map(function(timeStamp){
                            if (timeStamp.time == timeElement.time) {
                                timeElement.value = timeElement.value + (timeStamp.value * element.marketCap) 
                            }
                        })
                        return timeElement
                    })
                    const weightedData = baseData.map(function(indexValue) {
                        return {time : indexValue.time, value : Number(indexValue.value / (totalMarketCap * totalPrices))}
                    })
                    return weightedData[0]
                })
                setIndex(indexData)
            }
        }, [dataSource]);

    const columns = [
        {title: 'Name', dataIndex: 'name', key: 'name'},
        {title: 'Price', dataIndex: 'price', key: 'price', render:price => ( <NumericFormat value={price} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2}/>)},
        {title: 'Market Cap', dataIndex: 'marketCap', key: 'marketCap', render: marketCap => ( millify(marketCap))},
        {title: 'Weight', dataIndex: 'weight', key: 'weight', render:weight => ( <NumericFormat value={weight} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={2}/>)},
        {title: 'Action', dataIndex: '', key: 'x', render: (text, record) => (<span onClick={(e) => { handleDelete(record.line); }}><a>Delete</a></span>)},
    ];

    const handleDelete = (key) => {
        if (dataSource.filter((item) => item.line === key)) {
            setCounter(counter-1)
        }

        const newData = dataSource.filter((item) => item.line !== key);
        setDataSource(newData);
    };

    const handleReset = () => {
        const newData = []
        setDataSource(newData);
        setCounter(0)
    };

    const searchSuggestions = async (query) => {
        if (query && query.length >= 2) {
          const {data} = await axios.get(fetchSuggestions(query))
          return {data}
        }
    };

    const SelectSuggestions = async (value) => {
        const filter = suggestions.filter((line) => line.uuid == value)
        setCoinId(filter[0].uuid)
        form.resetFields(["autoComplete"]);
    }

    return (
        <>
            <Helmet>
                <title>Index - SoCoins</title>
                <meta name="description" content="Create your own crypto index" />
                <meta name="keywords" content="crypto, cryptocurrencies, create, index" />
            </Helmet>
            <Row>
                <Col xl={8} xs={24}>
                    <Row>
                        <Col xl={12} xs={12}>
                            <Form form={form} >
                                <Form.Item name="autoComplete">
                                        <AutoComplete id="autoComplete" placeholder="Add to index" style={{width: 200}} onChange={((query) => searchSuggestions(query).then((result) => setSuggestions(result?.data?.data?.coins)))} onSelect={((value) => SelectSuggestions(value))} allowClear={true}>
                                        {suggestions?.map((crypto) => (
                                            <Option id="options" key={crypto.uuid} value= {crypto.uuid}>
                                                {crypto.name}
                                            </Option>
                                        ))}
                                    </AutoComplete>
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col xl={12} xs={12}>
                            <Button onClick={() => handleReset()}>Reset</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table dataSource={dataSource} columns={columns} pagination={false}/>
                        </Col>
                    </Row>

                </Col>

                <Col xl={16} xs={24}>
                    {index?.length > 0 && dataSource?.length > 0 && index[0]?.value !== undefined ? (
                        <LightWeightChartx data = {index} coinName={'index'} delta = {200} className="crypto-chart"></LightWeightChartx>)
                        : ( <Loader />                    
                    )}
                </Col>
            </Row>
            
        </>
    )
}


export default CryptoIndex